import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";
import styled from "styled-components";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const HeaderSection = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: auto;
  flex-flow: row;
  background-color: rgba(30, 139, 195, 1);
`;

const HeaderText = styled.div`
  border: 0px solid green;
  font-family: montserrat;
  width: 100%;
`;
const List = styled.ul`
  margin: 0;
  padding: 1rem;
  color: white;
  background-color: (30, 139, 195, 1);
`;

const BreadCrumb = styled.li`
  display: inline;
  padding: 1rem;
`;
const BreadCrumbLink = styled(Link)`
  color: white;
  text-align: center;
  padding: 24px 16px;
  color: #ccc;
  text-decoration: none;
`;
const PanelRow = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-flow: column;
    padding: 1rem;
  }
  @media (min-width: 768px) {
    flex-flow: row;
    padding: 5rem;
  }
`;

const ContactContainer = styled.div`
  border-radius: 5px;
  background-color: #f2f2f2;
  width: 100%;
  color: black;
  @media (max-width: 768px) {
    padding: 10px;
  }
  @media (min-width: 768px) {
    padding: 20px;
  }
`;

const IndexPage = () => (
  <Layout>
    <hr />

    <div
      style={{
        color: "black",
        paddingLeft: "4rem",
        paddingRight: "4rem",
        textAlign: "center",
        marginTop: "4rem",
        marginBottom: "4rem",
      }}
    >
      <div class="heading text-center font-bold text-2xl m-5 text-gray-800">
        Pricing
      </div>

      <div class="w-full  pt-8">
        <div class="flex flex-col sm:flex-row justify-center mb-6 sm:mb-0 text-white-800 ">
          <div class="sm:flex-1 lg:flex-initial lg:w-1/4 rounded-t-lg rounded-tr-none  mt-4 flex flex-col shadow-lg ">
            <div class="p-8 text-3xl font-bold text-center">Free</div>
            <div class="border-0 border-grey-light border-t border-solid text-sm">
              <div class="text-center border-0 border-grey-light border-b border-solid py-4">
                1 Ice Cream
              </div>
              <div class="text-center border-0 border-grey-light border-b border-solid py-4">
                Unlimited toppings
              </div>
              <div class="text-center border-0 border-grey-light border-b border-solid py-4">
                Analytics
              </div>
            </div>
            <div class="text-center mt-8 mb-8 mt-auto">
              <a
                href="#"
                class="inline-block bg-blue-900 text-white px-6 py-4 rounded hover:bg-green-darker hover:text-white hover:no-underline"
              >
                Sign Up
              </a>
            </div>
          </div>
          <div class="flex-1 lg:flex-initial lg:w-1/4 rounded-t-lg bg-white mt-4 sm:-mt-4 shadow-lg z-30 flex flex-col">
            <div class="w-full p-8 text-3xl font-bold text-center">Basic</div>
            <div class="w-full border-0 border-grey-light border-t border-solid text-sm">
              <div class="text-center border-0 border-grey-light border-b border-solid py-4">
                2 Ice Creams
              </div>
              <div class="text-center border-0 border-grey-light border-b border-solid py-4">
                25 Cones
              </div>
              <div class="text-center border-0 border-grey-light border-b border-solid py-4">
                Unlimited toppings
              </div>
              <div class="text-center border-0 border-grey-light border-b border-solid py-4">
                Analytics
              </div>
            </div>
            <div class="w-full text-center px-8 pt-8 text-xl mt-auto">
              $2.99
              <span class="text-grey-light italic line-through">$4.99</span>
            </div>
            <div class="w-full text-center mb-8 mt-auto">
              <a
                href="#"
                class="inline-block bg-blue-900 text-white px-6 py-4 rounded hover:bg-green-darker hover:text-white hover:no-underline"
              >
                Sign Up
              </a>
            </div>
          </div>
          <div class="flex-1 lg:flex-initial lg:w-1/4 rounded-t-lg rounded-tl-none bg-white mt-4 flex flex-col shadow-lg ">
            <div class="p-8 text-3xl font-bold text-center">Pro</div>
            <div class="border-0 border-grey-light border-t border-solid text-sm">
              <div class="text-center border-0 border-grey-light border-b border-solid py-4">
                Unlimited Ice Creams
              </div>
              <div class="text-center border-0 border-grey-light border-b border-solid py-4">
                Unlimited Cones
              </div>
              <div class="text-center border-0 border-grey-light border-b border-solid py-4">
                Unlimited toppings
              </div>
              <div class="text-center border-0 border-grey-light border-b border-solid py-4">
                Analytics
              </div>
            </div>
            <div class="text-center px-8 pt-8 text-xl mt-auto">
              $5.99
              <span class="text-grey-light italic line-through">$9.99</span>
            </div>
            <div class="text-center pt-8 mb-8 mt-auto">
              <a
                href="#"
                class="inline-block bg-blue-900 text-white px-6 py-3 rounded hover:bg-green-darker hover:text-white hover:no-underline"
              >
                Sign Up
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
